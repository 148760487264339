import React, { useState, useEffect , useContext} from 'react';
import TicketFunction from './ticket_fucntion';
import './ticket_page.css';
import { useNavigate } from 'react-router-dom';
import Loading from '../../global_components/loading/loading.js';
import { Alert } from 'react-bootstrap';
import { UserContext } from '../../contexts/userContext';
const TicketsPage = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const {logout , isAuthenticated, user} = useContext(UserContext);

  useEffect(() => {
    TicketFunction.getUserTickets({user})
      .then(data => {
        setTickets(data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <Loading />;
  if (error) return <div className="err-container"><Alert variant="danger">{error}</Alert> </div> ;

  return (
    <div className="tickets-container">
      <h1>Your Tickets</h1>
      {tickets.map(ticket => (
        <div key={ticket._id} className="ticket-item">
          <h3>{`${ticket.schedule.departurePort.location} -> ${ticket.schedule.destinationPort.location}`}</h3>
          <p>Departure: {new Date(ticket.schedule.departureTime).toLocaleString()}</p>
          <button onClick={() => navigate(`/view-ticket/${ticket._id}`,{ state: { ticket: ticket } })}>View Ticket</button>
        </div>
      ))}
    </div>
  );
};

export default TicketsPage;
