// EditProfile.js
import React, { useState, useContext, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import EditProfileFunction from './edit_page_function.js';
import './edit_page.css';
import { UserContext } from '../../contexts/userContext.js';
import { useNavigate } from 'react-router-dom';

const EditProfile = () => {
  const navigate = useNavigate();
  const { user, setUser, logout } = useContext(UserContext);

  // State for form data and feedback messages
  const [formData, setFormData] = useState({
    email: user.email,
    fullname: user.fullname,
    username: user.username,
    dateOfBirth: user.dateOfBirth.toISOString().substring(0, 10), // Format date for the date input
    gender: user.gender,
  });
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackType, setFeedbackType] = useState(''); // 'success' or 'error'

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleEditSuccess = (updatedUserData) => {
    // Convert 'dateOfBirth' from string to Date object
    const dateOfBirthDateObject = new Date(updatedUserData.dateOfBirth);
  
    // Set the user with the updated information
    setUser({
      ...user,
      email: updatedUserData.email,
      fullname: updatedUserData.fullname,
      username: updatedUserData.username,
      dateOfBirth: dateOfBirthDateObject, // Use the Date object here
      gender: updatedUserData.gender,
      // ... any other fields that were updated
    });
  
    // ... any additional logic after successfully editing the profile
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await EditProfileFunction.editProfile(formData, user._id);
    if (response.success) {
      setFeedbackType('success');
      setFeedbackMessage('Profile updated successfully!');
      // Update user context with new data
      navigate('/')
      handleEditSuccess(response.updatedUserData)
    //   setUser({ ...user, ...formData });
    } else {
      setFeedbackType('error');
      setFeedbackMessage(response.error || 'Update failed. Please try again.');
    }
  };

  // Handle account deletion
  const handleDeleteAccount = async () => {
    const response = await EditProfileFunction.deleteAccount(user._id);
    if (response) {
      logout();
      navigate('/');
    } else {
      setFeedbackType('error');
      setFeedbackMessage(response.error || 'Unable to delete account. Please try again.');
    }
  };

  return (
    <div className="edit-profile-wrapper">
      <div className="edit-profile-container">
        <h2>Edit Profile</h2>
        {feedbackMessage && (
          <Alert variant={feedbackType === 'success' ? 'success' : 'danger'}>
            {feedbackMessage}
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="fullname">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              name="fullname"
              value={formData.fullname}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="dateOfBirth">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="gender">
            <Form.Label>Gender</Form.Label>
            <Form.Control
              as="select"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Select...</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </Form.Control>
          </Form.Group>

          <div class="button-group">
          <button onClick={handleChange}   className="w-50 mt-3 sch-book-now-btn" > Update Profile</button>
          <button onClick={handleDeleteAccount}   className="w-50 mt-3  btn btn-danger" > Delete Account</button>
        </div>
         
        </Form>
        </div>
    </div>
  );
};

export default EditProfile;
