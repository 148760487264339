import React, { useState, useContext } from 'react';
import "./landing_page.css"
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import truckImage from '../../assets/service-1.png'; // Update these paths
import planeImage from '../../assets/service-3.png'; // to point to your
import shipImage from'../../assets/service-2.png';
import aboutUs from'../../assets/about_us.png';
import view_ship from'../../assets/view_ship.png';
import { Container, Row, Col ,Button} from 'react-bootstrap';
import backgroundImage from '../../assets/header.png'; // Replace with the path to your image
import { UserContext } from '../../contexts/userContext';

  
function LandingPage() {
    const navigate = useNavigate();


  
  return (
    <div className="body" >
    <HeaderSection/>
    <ServiceSection/>
    <AboutSection/>
    <ViewShipSection/>
    {/* <CustomerFeedbackCarousel feedbacks={feedbacks} /> */}
{/* <Carousel/> */}
    </div>


  );
}

export default LandingPage;



const HeaderSection = () => {
  const { logout, isAuthenticated, user } = useContext(UserContext);

  return (
    <header className="marine-header">
    <div className="container h-100">
      <div className="row h-100 align-items-center">
        {/* Left Column for Text and Buttons */}
        <div className="col-md-6 text-black text-start">
          <h5>welcome to</h5>
          <h1 className="display-4">
          
              <span className="font-poppins">The Ultimate</span>{' '}
              <span className="font-nosifer">Marine</span>{' '}
              <span className="font-poppins">Traveler</span>
            
            </h1>
          <p className="lead">Embark on a journey across the majestic blues of our planet's oceans with The Ultimate Marine Traveler. Our curated sea voyages take you through the hidden wonders beneath the waves, from coral-laden reefs to serene coastal hideaways. </p>
<div class="button-group">
  <a href={isAuthenticated? '/book':'/signin'} class="button button-solid text-white " style={{textDecoration: "none"}}>Book a Ticket</a>
<a href='#service'  class="button button-outline"style={{textDecoration: "none"}}> View More</a>
</div>
        </div>
        
        {/* Right Column for Image */}
        <div className="col-md-6">
          <img src={backgroundImage} alt="Marine Traveler" className="img-fluid" />
        </div>
      </div>
    </div>
  </header>
  );
};

const ServiceSection =()=>{
  return (
     <div id='service' className="service-section" >

  <div class="container my-5">
<h2 class="text-center mb-4">Services we Offer</h2>

</div>
  <div className="container h-100 service-font">
    <div className="row h-100 align-items-center " data-aos="fade-up-right">
      <div  className="col-md-6 ">
        <img src={truckImage} alt="Marine Traveler" className="img-fluid" />
      </div>
      {/* Left Column for Text and Buttons */}
      <div className="col-md-6 text-black text-start " data-aos="fade-up-left">
        <h3 className='mb-3'>
           Speedy Tranportation
          </h3>
        <p className="lead">Experience swift, comfortable travel with our service, where we prioritize your time and comfort, ensuring a luxurious, hassle-free journey to your destination.</p>
{/* <div class="button-group">
<button class="button button-solid text-white ">Book a Ticket</button>
<button class="button button-outline">View More</button>
</div> */}
      </div>
      
      {/* Right Column for Image */}
      
    </div>
  </div>
  <div className="container h-100 service-font">
    <div className="row h-100 align-items-center ">
   
      {/* Left Column for Text and Buttons */}
      <div className="col-md-6 text-black text-start  " data-aos="fade-up-right">
        <h3 className='mb-3' >
           Secure Voyages
          </h3>
        <p className="lead">Ensuring your safety is our foremost concern, with rigorous measures and skilled professionals dedicated to a secure, worry-free journey.</p>
{/* <div class="button-group">
<button class="button button-solid text-white ">Book a Ticket</button>
<button class="button button-outline">View More</button>
</div> */}
      </div>
      
      {/* Right Column for Image */}
      <div className="col-md-6" data-aos="fade-up-left">
        <img src={shipImage} alt="Marine Traveler" className="img-fluid" />
      </div>
    </div>
  </div>
  <div className="container h-100 service-font">
    <div className="row h-100 align-items-center">
    <div className="col-md-6" data-aos="fade-up-right">
        <img src={planeImage} alt="Marine Traveler" className="img-fluid" />
      </div>
      {/* Left Column for Text and Buttons */}
      <div className="col-md-6 text-black text-start" data-aos="fade-up-left">
        <h3  className='mb-3'>
           Scenic View
          </h3>
        <p className="lead">Embark on a marine adventure filled with stunning vistas, where every moment offers a chance to witness the beauty of the sea.</p>
{/* <div class="button-group">
<button class="button button-solid text-white ">Book a Ticket</button>
<button class="button button-outline">View More</button>
</div> */}
      </div>
      
      {/* Right Column for Image */}
      
    </div>
  </div>
  

</div>


);
}

const AboutSection = () => {
  return (
    <div className="service-section">
      <div className="container h-100 service-font">
    <div className="row h-100 align-items-center ">
      
      {/* Left Column for Text and Buttons */}
      <div className="col-md-6 text-black text-start mb-5 ">
        <h3 className='mb-3'>
           About Us
          </h3>
        <p className="lead">Marine Traveler offers an unparalleled marine adventure, inviting you to experience breathtaking views and serene moments on the open sea. Our commitment to safety and comfort ensures a worry-free journey, allowing you to fully immerse in the beauty of your surroundings.</p>
<div class="button-group">
<button class="button button-solid text-white "> <a style={{textDecoration: "none", color: "white"}} href='/about'>View more</a> </button>
{/* <button class="button button-outline">View More</button> */}
</div>
      </div>
      
      {/* Right Column for Image */}
      <div className="col-md-6 ">
        <img src={aboutUs} alt="Marine Traveler" className="img-fluid" />
      </div>
      
    </div>
  </div>
    </div>
  );
};
const ViewShipSection =()=>{

  return (

    <section  className="marine-testimonials py-5" data-aos="fade-right" >
    <div className="service-section">
      <div className="container h-100 service-font">
    <div className="row h-100 align-items-center ">
      
      {/* Left Column for Text and Buttons */}
      <div className="col-md-6 text-black text-start  mb-5 ">
        <h3 className='mb-3'>
        Discover Over 10+ Different Ships
          </h3>
        <p className="lead">Explore our diverse fleet of over 10 ships, offering a range of options from luxurious yachts to efficient ferries, tailored for your ideal marine adventure.</p>
<div class="button-group">
<button class="button button-solid text-white "> <a style={{textDecoration: "none", color: "white"}} href='/fleet'>View more</a> </button>
{/* <button class="button button-outline">View More</button> */}
</div>
      </div>
      
      {/* Right Column for Image */}
      <div className="col-md-6 ">
        <img src={view_ship} alt="Marine Traveler" className="img-fluid" />
      </div>
      
    </div>
  </div>
    </div>
     </section>
  );
}

const CustomerFeedbackCarousel = ({ feedbacks }) => {
  return (
    <div id="customerFeedbackCarousel" className="carousel slide bg-warning" data-bs-ride="carousel">
      <div className="carousel-inner">
        {feedbacks.map((feedback, index) => (
          <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={feedback.id}>
            <div className="carousel-caption d-none d-md-block">
              <img src={feedback.image} alt={feedback.name} className="rounded-circle mb-3" />
              <h5>{feedback.name}</h5>
              <p className="text-warning">
                {Array(feedback.rating).fill().map((_, i) => (
                  <span key={i} className="fa fa-star checked"></span>
                ))}
              </p>
              <p>{feedback.comment}</p>
            </div>
          </div>
        ))}
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#customerFeedbackCarousel" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#customerFeedbackCarousel" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};


const Carousel = () => {
  return (
    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={shipImage} className="d-block w-50" alt="First slide" />
        </div>
        <div className="carousel-item">
          <img src={backgroundImage} className="d-block w-100" alt="Second slide" />
        </div>
        <div className="carousel-item">
          <img  src={shipImage} className="d-block w-100" alt="Third slide" />
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
    </div>
  );
};