import React from 'react';
import './footer.css'; // You can create a separate CSS file for the footer styles
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS if not already globally imported
import { Container, Row, Col } from 'react-bootstrap'; // If you are using react-bootstrap
// const Footer = () => {
//   return (
//     <footer className="footer">
//       <p>&copy; 2023 The Marine Traveler. All rights reserved.</p>
//     </footer>
//   );
// };

// export default Footer;


const Footer = () => {
  return (
    <footer className=" text-dark pt-4">
      <Container>
        <Row className="align-items-center">
          <Col md={4} className="mb-3 mb-md-0">
            <h5>THE MARINE TRAVELER</h5>
            <p>The Ultimate Marine Traveling System</p>
            {/* Social Icons */}
            <div>
              <a   className="footer-link me-2 "  href="#" ><i className="fab fa-facebook-f"></i></a>
              <a   className="footer-link me-2"  href="#" ><i className="fab fa-twitter"></i></a>
              <a   className="footer-link me-2"  href="#" ><i className="fab fa-linkedin-in"></i></a>
              <a   className="footer-link me-2"  href="#"><i className="fab fa-instagram"></i></a>
            </div>
          </Col>
          <Col md={2} className="mb-3 mb-md-0">
            <h5>Pages</h5>
            {/* Replace # with actual paths */}
            <ul className="list-unstyled footer-link">
              <li><a  className="footer-link"  href="/about">About Us</a></li>
              <li><a   className="footer-link"  href="/destinations">Destinations</a></li>
              <li><a  className="footer-link"  href="/schedule">Schedules</a></li>
              <li><a  className="footer-link"  href="/book">Book Ticket</a></li>
              <li><a  className="footer-link"  href="/fleet">Fleet</a></li>
            </ul>
          </Col>
          <Col md={3} className="mb-3 mb-md-0">
            <h5>Services</h5>
            <ul className="list-unstyled">
              <li  className="footer-link"> Book Ticket</li>
              <li className="footer-link"  >View ships</li>
              <li className="footer-link"  href="#">View ports</li>
              <li className="footer-link"  href="#">Download Ticket</li>
              <li className="footer-link"  href="#">Contact us</li>
            </ul>
          </Col>
          <Col md={3}>
            <h5>Contact</h5>
            <address>
              A38 Bethel Splendor,<br/>
              Babcock University<br/>
              <a className="footer-link"  href="mailto:contact@marinet.com">contact@marinet.com</a><br/>
              (903) 456 - 7890
            </address>
          </Col>
        </Row>
        <Row>
          <Col className="text-center py-3">
            <p>Copyright &copy; The Marine Traveler | Designed by CrownzDesigns</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
