import Constants from "../../utils/constant";

const BookTicketFunction = {
  
  getSchedules: async () => {
    // Simulated API call to fetch schedules
    // Get the current date and time
let currentDate = new Date();

// Get the date and time for 5 years from now
let futureDate = new Date();
futureDate.setFullYear(futureDate.getFullYear() + 5);

// Convert to strings in the desired format
let currentDateString = currentDate.toISOString().replace('Z', '').slice(0, -4);
let futureDateString = futureDate.toISOString().replace('Z', '').slice(0, -4);


    try {
      const response = await fetch(`${Constants.BASE_URL}/schedule/`,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        // body: JSON.stringify({
        //   'startDate': currentDateString,
        //   'endDate':futureDateString
        // })
    });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  },
  bookTicket: async (bookingData) => {
    // Simulated API call to book a ticket
    try {
      const response = await fetch(`${Constants.BASE_URL}/ticket/create`, { // Replace with your actual endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bookingData),
      });
      if (!response.ok) {
        throw new Error('Failed to book ticket');
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }
};

export default BookTicketFunction;
