import './App.css';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './main_screens/landing_page/landing_page';
import MainPage from './main_screens/main_page';
import { useEffect } from 'react';
import  AOS  from 'aos';
import "aos/dist/aos.css"
import AboutUsPage from './main_screens/aboutus_page/aboutus_page';
import ContactUsPage from './main_screens/contactus_page/contactus_page';
import SignUp from './main_screens/signup_page/signup';
import SignIn from './main_screens/signin_page/signin';
import ScheduleScreen from './main_screens/schedule_page/schedule_page';
import Fleet from './main_screens/fleet_page/fleet';
import Destinations from './main_screens/destination_page/destination';
import BookTicket from './main_screens/book_page/book_page';
import TicketsPage from './main_screens/ticket_page/ticket_page';
import ViewTicketPage from './main_screens/ticket_page/view_ticket_page/view_ticket';
// At the top of your index.js or App.js
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserCircle, faCog, faHistory, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import FAQPage from './main_screens/faq_page/faq_page';
import ViewDestination from './main_screens/destination_page/view_destination/view_destination';
import FleetDetail from './main_screens/fleet_page/view_fleet/view_fleet';
import EditPage from './main_screens/edit_page/edit_page';


// Add icons to the library for easy reference
library.add(faUserCircle, faCog, faHistory, faSignOutAlt);

function App() {
  useEffect(()=>{
    AOS.init();
    AOS.refresh();
  });
  const appStyle = {
    backgroundColor: "#f0f0f0", // Your desired background color
    // Any other styles you want to apply globally
  };
  return (
    <div style={appStyle}>
      <Routes>
    <Route path='/' element={<MainPage />}>
    <Route index element={<LandingPage />} />
    <Route path='/about'  element={<AboutUsPage />} />
    <Route path='/contact'  element={<ContactUsPage />} />
    <Route path='/schedule'  element={<ScheduleScreen />} />
    <Route path='/fleet'  element={<Fleet />} />
    <Route path='/destinations'  element={<Destinations />} />
    <Route path='/book'  element={<BookTicket />} />
    <Route path='/ticket'  element={<TicketsPage />}/>
    <Route path='/view-ticket/*'  element={<ViewTicketPage />}/>
    <Route path='/faq*'  element={<FAQPage />}/>
    <Route path="/view_destination" element={<ViewDestination />} />
    <Route path="/fleet/*" element={<FleetDetail />} />
    <Route path="/settings" element={<EditPage />} />



      
    </Route>
    <Route path='/signup' element={<SignUp />}/>
    <Route path='/signin' element={<SignIn />}/>

  
  </Routes>
    </div>
    
  );
}

export default App;
