// UserContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import User from '../models/userModel'; // Import the User model
import Constants from '../utils/constant';
import axios from 'axios';
 const UserContext = createContext();

// export const useUserContext = () => {
//   return useContext(UserContext);
// };

 const UserProvider = ({ children }) => {
  // Initialize user state with an instance of the User class
  const [user, setUser] = useState(
    new User('','', '', '', new Date(), '', '')
  );
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      const token = localStorage.getItem('userToken');
      if (token) {
        try {
         
          const response = await axios.get(`${Constants.BASE_URL}/auth/login/token`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

       
            var data = response.data;
          if (response.data.status) {

            const fetchedUser = data.user;
            setUser(new User(
              fetchedUser._id,
              fetchedUser.email,
              fetchedUser.fullname,
              fetchedUser.username,
              new Date(fetchedUser.dateOfBirth),
              fetchedUser.gender,
              token
            ));
            setIsAuthenticated(true);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          // Possible token is invalid or expired
          localStorage.removeItem('userToken');
          setIsAuthenticated(false);
        }
        setIsAuthenticated(true);

      }
    };

    getUserData();
  }, []);

  const login = (userData) => {
   
    localStorage.setItem('userToken', userData.token);
    setUser(new User(userData._id, userData.email, userData.fullname, userData.username, userData.dateOfBirth, userData.gender, userData.token));
    
    setIsAuthenticated(true);

  };

  const logout = () => {
    localStorage.removeItem('userToken');

    setUser( new User('', '', '', new Date(), '', ''));
   

    setIsAuthenticated(false);
  };

  return (
    <UserContext.Provider value={{ user, isAuthenticated, login, logout , setUser}}>
      {children}
    </UserContext.Provider>
  );
};
export { UserContext, UserProvider };