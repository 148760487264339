import React, { useState, useContext } from 'react';
import { Container, Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './navbar.css'; // Ensure your CSS path is correct
import Logo from '../assets/Marine_logo.png';

const NavBar = () => {
  const navigate = useNavigate();
  const [showDrawer, setShowDrawer] = useState(false);
  const { logout, isAuthenticated, user } = useContext(UserContext);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const navItems = ['Home', 'About Us', 'Destinations', 'Fleet', 'Schedule', 'Book a Ticket', 'Tickets', 'Contact', 'FAQ'];
  const navLinks = ['/', '/about', '/destinations', '/fleet', '/schedule', isAuthenticated? '/book':"/signin",isAuthenticated?  '/ticket': '/signin', '/contact', '/faq'];

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <Navbar  expand="lg" className="navbar-custom" fixed="top">
      <Container>
        <Link to="/" className='navbar-brand'>
          <img src={Logo}/>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav-center">
            {navItems.map((item, index) => (
              <Link key={index} to={navLinks[index]} className='nav-link'>
                {item}
              </Link>
            ))}
          </Nav>
          <div className="ms-auto">
            {isAuthenticated ? (
              <Dropdown align="end" className="profile-dropdown">
                <Dropdown.Toggle variant="transparent" id="dropdown-profile">
                  <FontAwesomeIcon icon={faUserCircle} size="2x" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/settings">
                    <FontAwesomeIcon icon={faCog} className="icon" /> Edit profile
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="icon" /> Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
<div class="button-group">
  <Link to="/signin" >
  <button  variant="outline-primary" className="button button-solid text-white " href="/signin">Sign In</button>

  </Link>
  <Link  to="/signup">
  <button variant="outline-primary" className="button button-outline  " href="/signup">Sign Up</button>

  </Link>
              </div>
            )}
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
