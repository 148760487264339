import Constants from "../../../utils/constant";

  
  const TicketFunction = {
    // ... other functions
  
    getTicketById: async (ticketId) => {
        console.log(ticketId);
      try {
        const response = await fetch(`${Constants.BASE_URL}/ticket/${ticketId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // Include other headers like authorization tokens if needed
          }
        });
  
        if (!response.ok) {
          throw new Error('Could not fetch the ticket');
        }
  
        const ticket = await response.json();
        return ticket;
      } catch (error) {
        console.error('Error fetching ticket by ID:', error);
        throw error;
      }
    },
  
    // ... other functions
  };
  
  export default TicketFunction;
  