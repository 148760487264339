// SignUp.js
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import SignUpFunction from './signup_function';
import './signup.css';
import { Link , useNavigate } from 'react-router-dom';
import authImg from '../../assets/Marine_logo.png'; // Update these paths

const SignUp = () => {
const navigate = useNavigate();

    const [formData, setFormData] = useState({
        password: '',
        email: '',
        fullname: '',
        username: '',
        dateOfBirth: '',
        gender: '',
    });
    const [feedbackMessage, setFeedbackMessage] = useState('');
const [feedbackType, setFeedbackType] = useState(''); // 'success' or 'error'


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        console.log(formData);

        e.preventDefault();
        const response = await SignUpFunction.signUp(formData);
        console.log(response);
        if (response.success) {
            setFeedbackType('success');
            setFeedbackMessage('Sign up successful! Please log in.'); 
            setTimeout(() => {
                navigate('/signin');
            }, 2000);  
            // Optionally: redirect the user to the login page or another location after a delay
        } else {
            setFeedbackType('error');
            setFeedbackMessage(response.error || 'Sign up failed. Please try again.');
        }
    };

    return (
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 left-pane d-none d-md-block">
            <div className="image-overlay">
              <h2>We are</h2>
              <h3>Here to make sailing beautiful</h3>
              <h4>Sail with us for a blissful experience</h4>
              <p>Already have an account?</p>
              <div className='overlay-bottom'>
              <a href="/signin" class="sign-in-link">Sign in</a>
    <Link to="/">
    <button class="go-back-btn">Go Back</button>
                      </Link>
              </div>
   
            </div>
          </div>
          <div className="col-md-6 right-pane">
            <div className="login-form">
              <img src={authImg} alt="Logo" className="logo" />
              <h2 className="nosifer-font">Register</h2>
              <Form onSubmit={handleSubmit}>
              <div className={`feedback-message ${feedbackType}`}>
{feedbackMessage}
</div>
        <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name="password" value={formData.password} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="fullname">
            <Form.Label>Full Name</Form.Label>
            <Form.Control type="text" name="fullname" value={formData.fullname} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control type="text" name="username" value={formData.username} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="dateOfBirth">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="gender">
            <Form.Label>Gender</Form.Label>
            <Form.Control as="select" name="gender" value={formData.gender} onChange={handleChange} required>
                <option value="none">None</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
            </Form.Control>
        </Form.Group>

       
        <button type="submit" className="button-auth ">Sign Up</button>
     </Form>
              
              
            </div>
          </div>
        </div>
      </div>

    );
};

export default SignUp;
//  <div className="signup-container">
{/* <div className={`feedback-message ${feedbackType}`}>
{feedbackMessage}
</div> */}
//     <Form onSubmit={handleSubmit}>
//         <Form.Group controlId="email">
//             <Form.Label>Email</Form.Label>
//             <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
//         </Form.Group>

//         <Form.Group controlId="password">
//             <Form.Label>Password</Form.Label>
//             <Form.Control type="password" name="password" value={formData.password} onChange={handleChange} required />
//         </Form.Group>

//         <Form.Group controlId="fullname">
//             <Form.Label>Full Name</Form.Label>
//             <Form.Control type="text" name="fullname" value={formData.fullname} onChange={handleChange} required />
//         </Form.Group>

//         <Form.Group controlId="username">
//             <Form.Label>Username</Form.Label>
//             <Form.Control type="text" name="username" value={formData.username} onChange={handleChange} required />
//         </Form.Group>

//         <Form.Group controlId="dateOfBirth">
//             <Form.Label>Date of Birth</Form.Label>
//             <Form.Control type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} required />
//         </Form.Group>

//         <Form.Group controlId="gender">
//             <Form.Label>Gender</Form.Label>
//             <Form.Control as="select" name="gender" value={formData.gender} onChange={handleChange} required>
//                 <option value="none">None</option>
//                 <option value="male">Male</option>
//                 <option value="female">Female</option>
//             </Form.Control>
//         </Form.Group>

       
//         <div className="signup-button-container">
//         <Link to="/">
//             <Button variant="outline-secondary" className="mr-2">Back</Button>
//         </Link>
//         <Button variant="primary" type="submit">Sign Up</Button>
//         </div>
//      </Form>
// </div> 