import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Loading from '../../global_components/loading/loading.js'; // Reuse the Loading component
import DestinationsFunction from './destination_function.js';
import './destination.css';
import { Link, useNavigate } from 'react-router-dom';

const Destinations = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [destinations, setDestinations] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const data = await DestinationsFunction.getDestinations();
        setDestinations(data);
      } catch (error) {
        setError(error.message);
      }
      setLoading(false);
    };

    fetchDestinations();
  }, []);
  const handleReadMoreClick = () => {
    // Define what happens when the button is clicked
    // For example, navigate to a different route or show more content
    
  };
  
  if (loading) return <Loading />;
  if (error) return <div className="error-state">Error: {error}</div>;

  return (
    <Container className="destinations-container">
      <Row>
        {destinations.map((destination) => (
          <Col md={4} key={destination._id} className="mb-4">
            <Card className="destination-card">
              <Card.Img variant="top" src={destination.image || "https://images.unsplash.com/photo-1552207802-77bcb0d13122?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2hpcHxlbnwwfHwwfHx8MA%3D%3D"} alt={destination.name} />
              <Card.Body>
                <Card.Title>{destination.name}</Card.Title>
                <Card.Text>{destination.location}</Card.Text>
                <Card.Text>{destination.description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut velit magna, porta et feugiat a, vulputate molestie sapien. Nulla consectetur lectus enim, eget venenatis eros viverra nec. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer scelerisque odio eu sodales gravida. Mauris laoreet sem nec est efficitur, ac malesuada ligula mattis. Cras vitae tincidunt nibh, et bibendum erat. Nunc gravida volutpat nisi, id sollicitudin velit finibus in. Duis placerat elit a massa tincidunt commodo. Curabitur est lacus, accumsan in arcu ut, pretium molestie velit. Praesent eget efficitur elit. Donec vestibulum interdum elit nec rhoncus. Cras tincidunt, ex in dapibus vulputate, justo nibh congue enim, auctor lacinia leo neque nec justo. Morbi eu massa vestibulum, tristique ligula eu, aliquam risus. Nam quis libero in justo varius pulvinar. Pellentesque ac lectus eget dui fermentum laoreet eget vel eros. Fusce dapibus est velit, vitae cursus nibh congue at."}</Card.Text>
                {/* Add the Read More button here */}
                <button   onClick={() => navigate('/view_destination', { state: { destination } })} variant="none" className="w-100 mt-3 ded-book-now-btn ">Read More</button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Destinations;
