// EditPageFunction.js
import axios from 'axios';
import Constants from '../../utils/constant';
// import Constants from '../utils/constant';

const editProfile = async (userData, id) => {
    console.log();
  try {
    const response = await axios.put(`${Constants.BASE_URL}/auth/user/edit/${id}`, userData, {
    //   headers: { Authorization: `Bearer ${userData.token}` },
    });
    console.log(response);
    if (response.data.status) {
      return { success: true, updatedUserData: response.data.user };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

const deleteAccount = async (id) => {
  try {
    const response = await axios.delete(`${Constants.BASE_URL}/auth/user/delete/${id}`, {
    //   headers: { Authorization: `Bearer ${userData.token}` },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export default { editProfile, deleteAccount };
