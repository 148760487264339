import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './schedule_page.css'; // Import the CSS file if needed
import ScheduleScreenFunction from './schedule_page_function'; // Import the utility class
import Loading from '../../global_components/loading/loading';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext.js';


// import './schedule_page_func'
const ScheduleScreen = () => {
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { logout, isAuthenticated, user } = useContext(UserContext);

  useEffect(() => {
    const fetchSchedules = async () => {
      const result = await ScheduleScreenFunction.fetchSchedules();
      if (result.success) {
        console.log(result.data);
        setSchedules(result.data);
      } else {
        setError(result.error);
      }
      setLoading(false);
    };

    fetchSchedules();
  }, []);

  if (loading) {
    return (
      <Loading/>
    );
  }
  

  if (error) {
    return (
      <div className="error-container">
        <img src="/error-image.png" alt="Error" />
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className='body'>
 <Container className="my-3 ">
      <Row xs={1} md={2} lg={3} className="g-4">
        {schedules.map((schedule) => (
          <Col key={schedule._id} >
            <Card className="h-100 schedule-card" data-aos="flip-right">
              <div className="schedule-image-container">
                <Card.Img variant="top" src={schedule.ship.image ||"https://images.unsplash.com/photo-1552207802-77bcb0d13122?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2hpcHxlbnwwfHwwfHx8MA%3D%3D"} className="schedule-image" />
              </div>
              <Card.Body>
                <Card.Title>{schedule.ship.name}</Card.Title>
                <Card.Text>Type: {schedule.ship.type}</Card.Text>
                <Card.Text>Capacity: {schedule.ship.capacity}</Card.Text>
                <Card.Text>Departure: {schedule.departurePort.name} - {schedule.departurePort.location}</Card.Text>
                <Card.Text>Destination: {schedule.destinationPort.name} - {schedule.destinationPort.location}</Card.Text>
                <Card.Text>Departure Time: {new Date(schedule.departureTime).toLocaleString()}</Card.Text>
                <button  onClick={() => navigate(isAuthenticated? '/book':'/signin')}    className="w-100 mt-3 sch-book-now-btn" >Book Now</button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    </div>
   
  );
};

export default ScheduleScreen;
