// Loading.js

import React from 'react';
import './loading.css'; // Make sure to create a Loading.css file with the CSS provided below

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="spinner"></div>
    </div>
  );
}

export default Loading;
