import Constants from "../../utils/constant";

// FleetFunction.js
const FleetFunction = {
    getFleets: async () => {
      try {
        const response = await fetch(`${Constants.BASE_URL}/ship/`); // Replace with your actual API endpoint
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        return { success: true, data };
      } catch (error) {
        return { success: false, message: error.message };
      }
    },
  };
  
  export default FleetFunction;
  