// Fleet.js
import React, { useState, useEffect } from 'react';
import { Container, ListGroup, Button, Row, Col, Image } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons'; // You might need to install `react-bootstrap-icons`
import Loading from '../../global_components/loading/loading.js'; // Reuse the Loading component from the previous example
import FleetFunction from './fleet_function.js';
import './fleet.css';
import { useNavigate } from 'react-router-dom';

const Fleet = () => {
  const [loading, setLoading] = useState(true);
  const [fleets, setFleets] = useState([]);
  const [error, setError] = useState('');

// Inside the Fleet component
const navigate = useNavigate();

const handleFleetClick = (fleet) => {
  navigate(`/fleet/${fleet._id}`, { state: { fleet } });
};

  useEffect(() => {
    const fetchFleets = async () => {
      const { success, data, message } = await FleetFunction.getFleets();
      if (success) {
        setFleets(data);
        setLoading(false);
      } else {
        setError(message);
        setLoading(false);
      }
    };

    fetchFleets();
  }, []);

  if (loading) return <Loading />;
  if (error) return <div className="error-state">Error: {error}</div>;

  return (
    <Container className="fleet-container">
      <ListGroup>
        {fleets.map((fleet, index) => (
          <ListGroup.Item key={fleet._id} className="fleet-item" data-aos="zoom-in-up"   onClick={() => handleFleetClick(fleet)}          >
            <Row className="align-items-center">
              <Col xs={4} sm={3} md={2} lg={2}>
              <Image src={fleet.image ||"https://images.unsplash.com/photo-1552207802-77bcb0d13122?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2hpcHxlbnwwfHwwfHx8MA%3D%3D"} thumbnail />
              </Col>
              <Col xs={8} sm={7} md={8} lg={9}>
                <div className="fleet-info">
                  <h5>{fleet.name}</h5>
                  <p>Type: {fleet.type}</p>
                  <p>Capacity: {fleet.capacity}</p>
                  <p className="fleet-description">{fleet.description}</p>
                </div>
              </Col>
              <Col xs={12} sm={2} md={2} lg={1}>
                <Button variant="link" className="arrow-button">
                  <ChevronRight size={25} />
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
    </ListGroup>
    </Container>
  );
}

export default Fleet;
