import Constants from "../../utils/constant";

// ScheduleScreenFunction.js
class ScheduleScreenFunction {
    static async fetchSchedules() {
      try {
        const response = await fetch(`${Constants.BASE_URL}/schedule/`);
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return { success: true, data };
      } catch (error) {
        return { success: false, error: error.message };
      }
    }
  }
  
  export default ScheduleScreenFunction;
  