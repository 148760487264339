// FleetDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import './view_fleet.css';
import { useLocation } from 'react-router-dom';

const FleetDetail = ({ fleetData }) => {
 
  const location = useLocation();
  const { fleet } = location.state || {};


  if (!fleet) {
    return <div>Fleet not found</div>;
  }

  return (
    <div className='body'>
      <header className="header-dest" style={{ backgroundImage: `url(${fleet.image})` }}>
        <div className="">
          <h1>{fleet.name}</h1>
          <h2>Type: {fleet.type} - Capacity: {fleet.capacity}</h2>
        </div>
      </header>
      <section className="fleet-details">
        <p>{fleet.description}</p>
        {/* ... other details */}
      </section>
    </div>
  );
};

export default FleetDetail;
