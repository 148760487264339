import React, { useState } from 'react';
import './contactus_page.css'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import mission from '../../assets/about_mission.png'; // Update these paths
import { Button, Form } from 'react-bootstrap';

export class MapContainer extends React.Component {
  render() {
    const mapStyles = {
      width: '100%',
      height: '100%',
    };

    return (
        
      <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={{ lat: 6.888229, lng: 3.722404 }}
      >
        <Marker position={{ lat: 6.888229, lng:3.722404  }} />
      </Map>
    );
  }
}

 GoogleApiWrapper({
  apiKey: 'AIzaSyC5tG6oR6w2vKxmR7F9PN93MmstFUkpReU' // Replace 'YOUR_GOOGLE_MAPS_API_KEY' with your actual API key
})(MapContainer);


function ContactUsPage() {
    const [formData, setFormData] = useState({
        password: '',
        email: '',
        fullname: '',
        phone: '',
        dateOfBirth: '',
        gender: '',
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleSubmit = async (e) => {
        console.log(formData);
        setFormData({
            password: '',
            email: '',
            fullname: '',
            phone: '',
            dateOfBirth: '',
            gender: '',
        })

        e.preventDefault();
      
        
    };
    return (
        <div className="contect-margin container h-100 service-font">
      <div className="row h-100 align-items-center " data-aos="fade-up-right">
      <div className="col-md-6 right-pane">
            <div className="login-form">
              <h1 className="display-5">
          
          <span className="font-poppins">Get in</span>{' '}
          <span className="nosifer-font">TOUCH</span>{' '}
          
        
        </h1>
              <Form onSubmit={handleSubmit}>
              <Form.Group controlId="fullname">
            <Form.Label>Full Name</Form.Label>
            <Form.Control type="text" name="fullname" value={formData.fullname} onChange={handleChange} required />
        </Form.Group>
      
        <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
        </Form.Group>

      

      

        <Form.Group controlId="username">
            <Form.Label>Phone number</Form.Label>
            <Form.Control type="text" name="phone" value={formData.phone} onChange={handleChange} required />
        </Form.Group>

      

        <Form.Group controlId="gender">
            <Form.Label>Where did you here about us?</Form.Label>
            <Form.Control as="select" name="gender" value={formData.gender} onChange={handleChange} required>
                <option value="none">None</option>
                <option value="male">Family</option>
                <option value="friend">Friend</option>
                <option value="Advertisment">Advertisment</option>
                <option value="female">Referal</option> 
            </Form.Control>
        </Form.Group>

       
        <button type="submit" className="button-auth ">SEND</button>
     </Form>
              
              
            </div>
          </div>
        {/* Left Column for Text and Buttons */}
        <div className="col-md-6 text-black text-start " data-aos="fade-up-left">
        
        <div className="map-parent">
        <iframe title='MyMap' className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.9876403100952!2d3.715570274838129!3d6.
        89208111877078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bd81639e54ec3%3A0x9129b4a8c83
        67e52!2sBabcock%20University%20Ilishan%20Remo!5e0!3m2!1sen!2sng!4v1700061080171!5m2!1sen!2sng" 
             allowfullscreen="" loading="lazy" referrerpolicy="no-ref
        errer-when-downgrade"></iframe>
       </div>

        </div>
        
        {/* Right Column for Image */}
        
      </div>
    </div>
        
    );
}

export default ContactUsPage;
