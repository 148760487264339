import Constants from "../../utils/constant";


const TicketFunction = {
    getUserTickets: async ({user}) => {
      // Here, we mock an API call to fetch user tickets
      // Replace with the actual API endpoint and include authorization if needed
      
      try {
        const response = await fetch(`${Constants.BASE_URL}/ticket/${user._id}/all`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: 'Bearer ' + userToken, // Uncomment if you have an auth token
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch tickets');
        }
        return await response.json();
      } catch (error) {
        throw error;
      }
    },
  };
  
  export default TicketFunction;
  