import React from 'react';
import { useLocation } from 'react-router-dom';
import "./view_destination.css"
const ViewDestination = () => {
  const location = useLocation();
  const { destination } = location.state; // Get the passed destination

  return (
    <div className=' body'>
      <header className='header-dest' style={{ backgroundImage: `url(${destination.image})` }}>
        <h1>{destination.name}</h1>
        <h2>({destination.location})</h2>
      </header>

      <main>
       
        <section className='details'>
          <h3>Description</h3>
          <p>{destination.description}</p>
        </section>
      </main>
    </div>
  );
};

export default ViewDestination;
