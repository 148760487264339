import React from 'react';
import './faq_page.css';

const FAQPage = () => {
  // Array of questions and answers
  const faqs = [
    {
      question: "How do I book a ticket for a marine journey?",
      answer: "Booking is simple. Visit our 'Book a Ticket' page, choose from available schedules, and proceed to payment."
    },
    {
      question: "What is the cancellation policy for tickets?",
      answer: "If you selected a premium type of ticket, you can reschedule your ticket, however NO ONE can be refunded after payment"
    },
    {
      question: "Are there discounts for frequent travelers?",
      answer: "Absolutely! Join our loyalty program for discounts, priority boarding, and more. Sign up on our website."
    },
    {
      question: "What amenities are on board?",
      answer: "Our ships feature dining areas, lounges, free Wi-Fi, entertainment options, and comfortable seating to ensure a pleasant journey."
    },
    {
      question: "Is there a luggage limit?",
      answer: "Passengers can bring one checked bag up to 20 kg and one carry-on item. Additional luggage may incur fees."
    },
    {
      question: "Do you provide food and beverages on board?",
      answer: "Yes, we have a selection of dining options available for purchase, including meals, snacks, and beverages."
    },
    {
      question: "How early should I arrive before departure?",
      answer: "We recommend arriving at least 1 hour before your departure time to allow for check-in and boarding."
    },
    {
      question: "Can I choose my seat when I book a ticket?",
      answer: "Yes, our booking system allows you to select your seat at the time of booking. Availability varies by ship."
    },
    {
      question: "What is the policy for traveling with pets?",
      answer: "Pets are welcome on board. They must be kept in an appropriate carrier during the journey and a pet ticket is required."
    },
    {
      question: "Are there any restrictions on what I can bring on board?",
      answer: "For safety reasons, hazardous materials, weapons, and illegal substances are prohibited. Personal items are subject to search."
    },
    {
      question: "What measures are in place to ensure safety during the journey?",
      answer: "Our ships are equipped with the latest safety technology, and our crew is trained in emergency procedures to ensure a safe trip."
    },
    {
      question: "Can I change my ticket after booking?",
      answer: "Changes are possible up to 24 hours before departure. Please contact customer service for assistance."
    },
    {
      question: "Do you offer any special assistance for passengers with disabilities?",
      answer: "We strive to accommodate all passengers. Please inform us of any special needs at the time of booking."
    },
    {
      question: "What should I do if I lose my belongings during the trip?",
      answer: "Report any lost items to our staff immediately. We have a lost and found service at our main office."
    },
    {
      question: "Are there any age restrictions for travelers?",
      answer: "Children under 12 must be accompanied by an adult. There are no upper age limits, but travelers should be fit to travel by sea."
    }
  ];

  return (
    <div className="faq-page">
      <h1>Frequently Asked Questions</h1>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQPage;
