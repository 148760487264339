// SignIn.js
import React, { useState, useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import SignInFunction from './signin_function';  // This will be the function handling the sign-in request
// import './signin.css';  // CSS specific to this component
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext';  // Adjust the path accordingly
import authImg from '../../assets/Marine_logo.png'; // Update these paths
import './Login.css'; // assuming your custom styles are in Login.css

const SignIn2 = () => {
    const {login} = useContext(UserContext);
    // const { theme, toggleTheme } = useContext(ThemeContext);

    // const { login , user} = useUserContext();

    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [feedbackType, setFeedbackType] = useState('');

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await SignInFunction.signIn(formData);

        if (response.success) {
            const { _id,  email, fullname, username, dateOfBirth, gender, token } = response.data.user;
            

        // Convert dateOfBirth string to Date object
        const dob = new Date(dateOfBirth);

        // Update the user context with the user data
        login({
            _id: _id,
            email: email,
            fullname: fullname,
            username: username,
            dateOfBirth: dob,
            gender: gender,
            token: token
        });

            setFeedbackType('success');
            setFeedbackMessage('Sign in successful! Redirecting...');
            // Optionally: Handle successful sign-in logic here
            // e.g., store user token, update app state, etc.

            // Redirect the user to another page after a short delay
            setTimeout(() => {
                navigate('/');  // Assuming you have a dashboard or similar route
            }, 2000);
        } else {
            setFeedbackType('error');
            setFeedbackMessage(response.error || 'Sign in failed. Please try again.');
        }
    };

    return (
        <div className="wrapper">
 <div className="container h-100 service-font">
    <div className="row h-100 align-items-center " data-aos="fade-up-right">
      <div  className="col-md-6 ">
        <img src={authImg} alt="Marine Traveler" className="img-fluid" />
      </div>
      {/* Left Column for Text and Buttons */}
      <div className="signin-container">
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="text" name="username" value={formData.username} onChange={handleChange} required />
                </Form.Group>

                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" name="password" value={formData.password} onChange={handleChange} required />
                </Form.Group>

                <div className="signin-button-container">
                    <Link to="/">
                        <Button variant="outline-secondary" className="mr-2">Back</Button>
                    </Link>
                    <Button variant="primary" type="submit">Sign In</Button>
                </div>

                <div className={`feedback-message ${feedbackType}`}>
                    {feedbackMessage}
                </div>
            </Form>
        </div>
      
      {/* Right Column for Image */}
      
    </div>
  </div>
       
        </div>

    );
};

// export default SignIn2;

// import 'bootstrap/dist/css/bootstrap.min.css'; // make sure to import Bootstrap CSS

const SignIn = () => {
    const {login} = useContext(UserContext);
    // const { theme, toggleTheme } = useContext(ThemeContext);

    // const { login , user} = useUserContext();

    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [feedbackType, setFeedbackType] = useState('');

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await SignInFunction.signIn(formData);

        if (response.success) {
            const { _id,  email, fullname, username, dateOfBirth, gender, token } = response.data.user;
            

        // Convert dateOfBirth string to Date object
        const dob = new Date(dateOfBirth);

        // Update the user context with the user data
        login({
            _id: _id,
            email: email,
            fullname: fullname,
            username: username,
            dateOfBirth: dob,
            gender: gender,
            token: token
        });

            setFeedbackType('success');
            setFeedbackMessage('Sign in successful! Redirecting...');
            // Optionally: Handle successful sign-in logic here
            // e.g., store user token, update app state, etc.

            // Redirect the user to another page after a short delay
            setTimeout(() => {
                navigate('/');  // Assuming you have a dashboard or similar route
            }, 2000);
        } else {
            setFeedbackType('error');
            setFeedbackMessage(response.error || 'Sign in failed. Please try again.');
        }
    };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 left-pane d-none d-md-block">
          <div className="image-overlay">
            <h2>We are</h2>
            <h3>Here to make sailing beautiful</h3>
            <h4>Sail with us for a blissful experience</h4>
            <p>Don't have an account?</p>
            <div className='overlay-bottom'>
            <a href="/signup" class="sign-in-link">Sign up</a>
  <Link to="/">
  <button class="go-back-btn">Go Back</button>
                    </Link>
            </div>
 
          </div>
        </div>
        <div className="col-md-6 right-pane">
          <div className="login-form">
            <img src={authImg} alt="Logo" className="logo" />
            <h2 className="nosifer-font">WELCOME</h2>
            <Form onSubmit={handleSubmit}>
            <div className={`feedback-message ${feedbackType}`}>
                    {feedbackMessage}
                </div>
                <Form.Group controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="text" name="username" value={formData.username} onChange={handleChange} required />
                </Form.Group>

                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" name="password" value={formData.password} onChange={handleChange} required />
                </Form.Group>
                
                <button type="submit" className="button-auth ">Login</button>
              <p className="mobile-signup-text d-md-none">Don't have an account? </p>
              <p className="mobile-signup-text d-md-none"> <a className='link' href="/signup">Sign Up</a></p>

               
            </Form>
            
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
