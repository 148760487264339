import Constants from "../../utils/constant";

// signupFunction.js
class SignUpFunction {
    static async signUp(userData) {
        try {
            const response = await fetch(`${Constants.BASE_URL}/auth/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData)
            });
            const data = await response.json();
            console.log(data);
            if (response.status === 200) {
                return { success: true, data };
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            return { success: false, error: error.message };
        }
    }
}

export default SignUpFunction;
