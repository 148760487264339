// signInFunction.js
import Constants from "../../utils/constant";
class SignInFunction {
    static async signIn(userData) {
        try {
            const response = await fetch(`${Constants.BASE_URL}/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });
            const data = await response.json();
            if (response.status === 200) {
                return { success: true, data };
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            return { success: false, error: error.message };
        }
    }
}

export default SignInFunction;
