import React, { Fragment } from 'react';
import NavBar from '../../global_components/navbar';
import './aboutus_page.css'
import aboutHeader from '../../assets/about_header.png'; // Update these paths
import mission from '../../assets/about_mission.png'; // Update these paths
import about_value from '../../assets/about_values.png'; // to point to your
import about_vision from'../../assets/about_vision.png';
import aboutUs from'../../assets/about_us.png';
import view_ship from'../../assets/view_ship.png';
import Footer from '../../global_components/footer';
function AboutUsPage() {
    return (
        <Fragment>
            
            <div className="service-section" >

  <div className="row h-100 align-items-center " data-aos="fade-up-right">
    <div  className="col-md-6 ">
      <img src={aboutHeader} alt="Marine Traveler" className="img-fluid" />
    </div>
    {/* Left Column for Text and Buttons */}
    <div className="col-md-6 text-black text-start">
          <h1 className="display-4">
          
              <span className="font-poppins">About</span>{' '}
              <span className="font-nosifer">Us</span>{' '}
              
            
            </h1>
          <p className="lead">Marine Traveler offers an unparalleled marine adventure, inviting you to experience breathtaking views and serene moments on the open sea. Our commitment to safety and comfort ensures a worry-free journey, allowing you to fully immerse in the beauty of your surroundings.</p>

        </div>
    </div>
    
    </div>
    <ServiceSection/>
        </Fragment>
       
    );
}

export default AboutUsPage;

const ServiceSection =()=>{
    return (
       <div className="service-section" >

    <div className="container h-100 service-font">
      <div className="row h-100 align-items-center " data-aos="fade-up-right">
        <div  className="col-md-6 ">
          <img src={mission} alt="Marine Traveler" className="img-fluid" />
        </div>
        {/* Left Column for Text and Buttons */}
        <div className="col-md-6 text-black text-start " data-aos="fade-up-left">
          <h3 className='mb-3'>
          Our Mission
            </h3>
          <p className="lead">At The Marine Traveler, our mission is to revolutionize transportation in Nigeria by promoting the use of boats and ships. We aim to provide safe, reliable, and efficient water-based transportation options to connect communities and enhance economic growth.</p>
  {/* <div class="button-group">
  <button class="button button-solid text-white ">Book a Ticket</button>
  <button class="button button-outline">View More</button>
  </div> */}
        </div>
        
        {/* Right Column for Image */}
        
      </div>
    </div>
    <div className="container h-100 service-font">
      <div className="row h-100 align-items-center ">
     
        {/* Left Column for Text and Buttons */}
        <div className="col-md-6 text-black text-start  " data-aos="fade-up-right">
          <h3 className='mb-3' >
             Our Vision
            </h3>
          <p className="lead">Our vision is to create a sustainable and interconnected water transport system in Nigeria, utilizing the vast network of rivers, lakes, and coastlines. We believe that harnessing the power of water transportation can reduce congestion, minimize environmental impact, and offer a more accessible means of travel.</p>
  {/* <div class="button-group">
  <button class="button button-solid text-white ">Book a Ticket</button>
  <button class="button button-outline">View More</button>
  </div> */}
        </div>
        
        {/* Right Column for Image */}
        <div className="col-md-6" data-aos="fade-up-left">
          <img src={about_vision} alt="Marine Traveler" className="img-fluid" />
        </div>
      </div>
    </div>
    <div className="container h-100 service-font">
      <div className="row h-100 align-items-center">
      <div className="col-md-6" data-aos="fade-up-right">
          <img src={about_value} alt="Marine Traveler" className="img-fluid" />
        </div>
        {/* Left Column for Text and Buttons */}
        <div className="col-md-6 text-black text-start" data-aos="fade-up-left">
          <h3  className='mb-3'>
             Our Values
            </h3>
          <p className="lead">Our core values are centered around making water transportation accessible and inclusive for every Nigerian, ensuring that our services reach far and wide. We place the highest importance on the safety and well-being of our passengers and crew, and are committed to eco-friendly and sustainable practices in our operations.</p>
  {/* <div class="button-group">
  <button class="button button-solid text-white ">Book a Ticket</button>
  <button class="button button-outline">View More</button>
  </div> */}
        </div>
        
        {/* Right Column for Image */}
        
      </div>
    </div>
    
  
  </div>
  
  
  );
  }

<div className='aboutus-container'>
<header data-aos="fade-down"
>
    <h1>About The Marine Traveler</h1>
</header>

<section className="mission" data-aos="flip-left">
    <h2>Our Mission</h2>
    <p>
        At The Marine Traveler, our mission is to revolutionize transportation in Nigeria by promoting the use of boats and ships. We aim to provide safe, reliable, and efficient water-based transportation options to connect communities and enhance economic growth.
    </p>
</section>

<section className="vision" data-aos="flip-right">
    <h2>Our Vision</h2>
    <p>
        Our vision is to create a sustainable and interconnected water transport system in Nigeria, utilizing the vast network of rivers, lakes, and coastlines. We believe that harnessing the power of water transportation can reduce congestion, minimize environmental impact, and offer a more accessible means of travel.
    </p>
</section>

<section className="values" data-aos="flip-left">
    <h2>Our Values</h2>
    <ul>
        <li>Accessibility: We are committed to making water transport accessible to all Nigerians.</li>
        <li>Safety: The safety of our passengers and crew is our top priority.</li>
        <li>Sustainability: We promote eco-friendly and sustainable practices in water transportation.</li>
        <li>Innovation: We constantly strive to innovate and improve our services.</li>
        <li>Community: We are dedicated to building strong, connected communities through our services.</li>
    </ul>
</section>



</div>
