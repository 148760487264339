// UserModel.js
export default class User {
    constructor(id, email, fullname, username, dateOfBirth, gender, token) {
      this._id = id;
      this.email = email;
      this.fullname = fullname;
      this.username = username;
      this.dateOfBirth = dateOfBirth;
      this.gender = gender;
      this.token = token;
    }
  }
  