import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';
import BookTicketFunction from './book_page_fuction';
import './book_page.css';
import Loading from '../../global_components/loading/loading.js';
import { UserContext } from '../../contexts/userContext.js';
import { useNavigate } from 'react-router-dom';
import { a } from 'react-spring';

const BookTicket = () => {
  const [loading, setLoading] = useState(true);
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState('');
  const [bookingForFriend, setBookingForFriend] = useState(false);
  const [friendUsername, setFriendUsername] = useState('');
  const [ticketType, setTicketType] = useState('regular');
  // const [error, setError] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackType, setFeedbackType] = useState('');
  const {user , isAuthenticated} = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    BookTicketFunction.getSchedules()
      .then(data => {
        console.log(data);
        setSchedules(data);
        setLoading(false);
      })
      .catch(err => {
        setFeedbackType('error');
        setFeedbackMessage(err.message || 'Failed to book ticket');      
          setLoading(false);
      });
  }, []);
  function calculateAmount(a,b){

  }
  const handlePayment = async (event) => {
    event.preventDefault();
    
    
    // Validation could be added here
    if (selectedSchedule) {
       // Assuming `selectedSchedule` and `ticketType` are already defined and contain valid data.
  var amountToPay =20000// calculateAmount(selectedSchedule, ticketType); // Implement this function based on your pricing
  if(bookingForFriend){
    amountToPay += 5000
  }
  if(ticketType!=='regular'){
    amountToPay += 10000
  }
  
  const paystackPublicKey = 'pk_test_11ecc818f4c489bc7fd762c3f83b79d66e80928c'; // Replace with your Paystack public key
  const handler = window.PaystackPop.setup({
    key: paystackPublicKey,
    amount: amountToPay * 100, // Convert to kobo
    email: user.email, // Replace with user's email
    callback: function(transaction){
      // Handle successful transaction here
     
      completeBooking(transaction.reference);
    },
    onCancel: () => {
      // Handle cancelled transaction here
      alert('Payment cancelled');
    },
  });

  handler.openIframe();
    } else {
      setFeedbackType('error');
      setFeedbackMessage( 'select a schedule');    
      }
    
 
};

  const completeBooking = async (reference) => {
    
    try {
      const response = await BookTicketFunction.bookTicket({
        schedule: selectedSchedule,
        user: user._id,
        username: bookingForFriend ? friendUsername : user.username,
        isReschedulable: ticketType==='regular'?false:true,
      });
      console.log(response);
      if (response) {
        // Redirect to success page or handle the successful booking
        setFeedbackType('success');
        setFeedbackMessage('Booking successful! Redirecting...');
        setTimeout(() => {
          navigate('/ticket');
        }, 1500);
      } else {
        setFeedbackType('error');
        setFeedbackMessage(response.message || 'Failed to book ticket');
        // Handle error in booking ticket

      }
    } catch (error) {
      // Handle error in booking ticket
      setFeedbackType('error');
      setFeedbackMessage('Error Occured during Ticket Booking');

    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setError(null);
    // Validation could be added here
    if (selectedSchedule) {
      try {
        // Payment processing would go here
        // For now, let's assume booking is successful after payment
        // A real-world application would handle the payment first
        // Then book the ticket if payment is successful

        const response = await BookTicketFunction.bookTicket({
          schedule: selectedSchedule,
          user: user._id, // Replace with actual user ID
          username: bookingForFriend ? friendUsername : user.username,
          ticketType: ticketType
        });

        // Redirect to success page or handle the successful booking
      } catch (err) {
      }
    } else {
    }
  };

  if (loading) {
    return (
      <Loading/>
    );
  }
  

  return (
    <Container className="book-ticket-container">
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2 className="text-center mb-4">Book a Ticket</h2>
          {feedbackMessage && (
            <Alert variant={feedbackType === 'success' ? 'success' : 'danger'}>
              {feedbackMessage}
            </Alert>
          )}          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formScheduleSelection">
              <Form.Label>Select Schedule</Form.Label>
              <Form.Control
                as="select"
                value={selectedSchedule}
                onChange={e => setSelectedSchedule(e.target.value)}
                className="mb-3"
              >
                <option value="">Select a schedule</option>
                {schedules.map(schedule => (
                  <option key={schedule._id} value={schedule._id}>
                    {`${schedule.departurePort.location} -> ${schedule.destinationPort.location} Date & Time: ${new Date(schedule.departureTime).toLocaleString()}`}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formBookingForFriend">
              <Form.Check
                type="checkbox"
                label="Booking for a friend?"
                checked={bookingForFriend}
                onChange={e => setBookingForFriend(e.target.checked)}
                className="mb-3"
              />
            </Form.Group>

            {bookingForFriend && (
              <Form.Group controlId="formFriendsUsername">
                <Form.Label>Friend's Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter friend's username"
                  value={friendUsername}
                  onChange={e => setFriendUsername(e.target.value)}
                  className="mb-3"
                />
              </Form.Group>
            )}

            <Form.Group controlId="formTicketType">
              <Form.Label>Ticket Type</Form.Label>
              <Form.Control
                as="select"
                value={ticketType}
                onChange={e => setTicketType(e.target.value)}
                className="mb-3"
              >
                <option value="regular">Regular</option>
                <option value="premium">Premium</option>
              </Form.Control>
            </Form.Group>

            <div className="d-grid gap-2">
              <button onClick={handlePayment} className='book-now-btn w-100 mt-3'>
                Proceed to Payment
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default BookTicket;
