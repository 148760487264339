import React, { Fragment } from 'react';
import '../main_screens/main_page.css'; // You can define your styles in App.css
import { Outlet } from 'react-router-dom';
import NavBar from '../global_components/navbar';
import Footer from '../global_components/footer';
import TopBar from '../global_components/top_bar/top_bar';

function MainPage() {
  return (
    <Fragment>
      {/* <TopBar/> */}
      <NavBar/>
    <div id="root">
      {/* Navbar */}
      <div className="main-content">
      <Outlet/>

      </div>
      

      {/* Footer */}
      <Footer/>
    </div>
    </Fragment>
    
  );
}

export default MainPage;
