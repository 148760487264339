import React, { useState, useContext, useEffect } from 'react';
import './view_ticket.css';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ViewTicketFunction from './view_ticket_function'
import Loading from '../../../global_components/loading/loading';
const ViewTicketPage = ({ tickets }) => {
    const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    setTicket(location.state.ticket)
    
  }, []);
  const downloadTicketAsPDF = async () => {
    const input = document.getElementById('ticketToDownload');
    
    // Ensure that the element is fully on-screen and not hidden or collapsed
    input.style.display = 'block';
  
    // Wait for a potential reflow if needed
    await new Promise(resolve => setTimeout(resolve, 100));
  
    const canvas = await html2canvas(input, {
      scale: 1, // You can adjust the scale if the output is too large or too small
      useCORS: true, // If there are images from other domains, this can help
      allowTaint: false, // Set to true only if the images are from the same origin
      logging: true, // Enable for debugging
      width: input.clientWidth, // Explicitly set width to match element
      height: input.clientHeight, // Explicitly set height to match element
      windowWidth: document.documentElement.offsetWidth, // Set window width
      windowHeight: document.documentElement.offsetHeight // Set window height
    });
  
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: [canvas.width, canvas.height] // Set the PDF page size to the canvas size
    });
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
    pdf.save(`${ticket.user.username}-${ticket._id}.pdf`);
  
    // Reset the display style if needed
    input.style.display = '';
  };
  
//   if (loading) return <Loading />;
  if (error) return <div>Error: {error}</div>;
  if (!ticket) {
    navigate('/tickets');
    return null;
  }

  return (
    <div className="view-ticket-container">
  <h1 className="ticket-title">View Ticket</h1>
<div id="ticketToDownload" className="ticket">
  <div className="ticket-header">
    <h2>Boarding Pass</h2>
  </div>
  <div className="ticket-section">
    <h3>Passenger Information</h3>
    <p>Name: {ticket.user.fullname}</p>
    <p>Username: {ticket.user.username}</p>
    <p>Email: {ticket.user.email}</p>
    <p>Date of Birth: {new Date(ticket.user.dateOfBirth).toLocaleDateString()}</p>
  </div>
  <div className="ticket-section">
    <h3>Ship Information</h3>
    <p>Name: {ticket.schedule.ship.name}</p>
    <p>Type: {ticket.schedule.ship.type}</p>
    <p>Capacity: {ticket.schedule.ship.capacity}</p>
  </div>
  <div className="ticket-section">
    <h3>Journey Details</h3>
    <p>Departure: {ticket.schedule.departurePort.name} - {ticket.schedule.departurePort.location}</p>
    <p>Destination: {ticket.schedule.destinationPort.name} - {ticket.schedule.destinationPort.location}</p>
    <p>Departure Time: {new Date(ticket.schedule.departureTime).toLocaleString()}</p>
  </div>
  <div className="ticket-section">
    <h3>Ticket Options</h3>
    <p>Type: {ticket.isReschedulable ? 'Premium' : 'Regular'}</p>
    <p>Reschedulable: {ticket.isReschedulable ? 'Yes' : 'No'}</p>
  </div>
  <div className="ticket-footer">
    <p>Thank you for choosing our service.</p>
  </div>
</div>

<button onClick={downloadTicketAsPDF} className="download-ticket-btn">Download Ticket</button>
    </div>
  );
};

export default ViewTicketPage;
