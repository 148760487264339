import Constants from "../../utils/constant";

// This will handle fetching data from the backend.
const DestinationsFunction = {
    getDestinations: async () => {
      // Replace with the actual API endpoint
      const response = await fetch(`${Constants.BASE_URL}/port/`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }
  };
  
  export default DestinationsFunction;
  